import type { SearchOutfittersForSportsmenQueryVariables } from "~graphql/generated/graphql"
import { useSearchOutfittersForSportsmenQuery } from "~graphql/generated/graphql"

const DEFAULT_SEARCH_SIZE = 20
const DEFAULT_OPTIONS = { from: 0, size: DEFAULT_SEARCH_SIZE }

interface Props {
    searchParams: SearchOutfittersForSportsmenQueryVariables["searchParams"]
    options?: SearchOutfittersForSportsmenQueryVariables["options"]
    skip?: boolean
}

export function useOutfitterSearch({
    searchParams,
    options = DEFAULT_OPTIONS,
    skip,
}: Props) {
    const {
        data,
        loading: isLoading,
        error,
    } = useSearchOutfittersForSportsmenQuery({
        variables: {
            searchParams,
            options,
        },
        skip,
    })

    const outfitters = data?.searchOutfittersForSportsmen.results ?? []
    const total = data ? data.searchOutfittersForSportsmen.total : 0

    return { outfitters, isLoading, error, total }
}
