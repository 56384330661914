import React from "react"
import {
    ButtonVariant,
    HorizontalStack,
    PillButton,
} from "@mallardbay/lib-react-components"

import { useAuth } from "~utils/auth-hooks"
import COPY from "~config/copy-constants"

export default function HeaderLoginButtons() {
    const { loginWithRedirect } = useAuth()

    const handleSignIn = () => loginWithRedirect({ isSignup: false })

    const handleSignup = () => loginWithRedirect({ isSignup: true })

    return (
        <HorizontalStack>
            <PillButton variant={ButtonVariant.Solid} onClick={handleSignup}>
                {COPY.SIGN_UP}
            </PillButton>
            <PillButton variant={ButtonVariant.Outline} onClick={handleSignIn}>
                {COPY.SIGN_IN}
            </PillButton>
        </HorizontalStack>
    )
}
