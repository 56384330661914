import React from "react"
import {
    componentStyles,
    Span,
    Text,
    useTheme,
    ExternalLink,
} from "@mallardbay/lib-react-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope"
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt"
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone"

import { SUPPORT_CONTACTS } from "~config/constants"
import { getKnowledgeBaseUrl } from "~utils/navigation-helpers"
import COPY_CONSTANTS from "~config/copy-constants"

export const TYPES = {
    PHONE: "PHONE",
    EMAIL: "EMAIL",
    KNOWLEDGE_BASE: "KNOWLEDGE_BASE",
} as const

type LinkType = keyof typeof TYPES

interface Props {
    readonly type: LinkType
    readonly isBold?: boolean
    readonly isUnderlined?: boolean
    readonly copyPrefix?: string
    readonly shouldShowIcon?: boolean
    readonly copySuffix?: string
}

export default function SupportLink({
    type,
    isBold = true,
    isUnderlined = false,
    copyPrefix = "",
    copySuffix = "",
    shouldShowIcon = false,
}: Props) {
    const styles = useStyles({ isBold, isUnderlined })

    const config = getConfig(type)

    return (
        <div>
            <ExternalLink href={config.link}>
                {shouldShowIcon && (
                    <Span style={styles.iconContainer}>
                        <FontAwesomeIcon icon={config.icon} />{" "}
                    </Span>
                )}
                {copyPrefix && <Text>{copyPrefix} </Text>}
                <Text style={styles.text}>{config.linkCopy}</Text>
                {copySuffix && <Text>{copySuffix} </Text>}
            </ExternalLink>
        </div>
    )
}

function useStyles({
    isBold,
    isUnderlined,
}: {
    isBold?: boolean
    isUnderlined?: boolean
}) {
    const { colors } = useTheme()

    return componentStyles({
        container: {
            gap: "6px",
        },
        iconContainer: {
            color: colors.primaryButtonColor,
            marginRight: "5px",
        },
        text: {
            fontWeight: isBold ? 700 : 400,
            display: "inline",
            textDecoration: isUnderlined ? "underline" : "none",
        },
    })
}

function getConfig(type: LinkType) {
    const configs = {
        [TYPES.EMAIL]: {
            link: `mailto:${SUPPORT_CONTACTS.EMAIL}`,
            linkCopy: SUPPORT_CONTACTS.EMAIL,
            icon: faEnvelope,
        },
        [TYPES.PHONE]: {
            link: `tel:${SUPPORT_CONTACTS.PHONE}`,
            linkCopy: SUPPORT_CONTACTS.PHONE,
            icon: faPhone,
        },
        [TYPES.KNOWLEDGE_BASE]: {
            link: getKnowledgeBaseUrl(),
            linkCopy: COPY_CONSTANTS.HERE,
            icon: faExternalLinkAlt,
        },
    }

    return configs[type]
}
