import React from "react"
import { Button } from "@mallardbay/lib-react-components"
import type { ButtonProps } from "@mallardbay/lib-react-components"
import { useTheme } from "@mallardbay/lib-react-components"

interface Props extends ButtonProps {
    readonly fullwidth?: boolean
}

// TODO move away from this component
export default function PrimaryGradientButton({ fullwidth, ...props }: Props) {
    const theme = useTheme()
    const baseStyle = {
        // TODO The original component had margin. Remove it and account for externally
        marginTop: theme.space[5],
        marginBottom: theme.space[5],
    }
    const style = fullwidth ? { width: "100%", ...baseStyle } : baseStyle
    return <Button {...props} style={style} gradientEnabled />
}
