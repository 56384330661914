import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons/faArrowRightFromBracket"
import { faBoxArchive } from "@fortawesome/free-solid-svg-icons/faBoxArchive"
import { faHeadset } from "@fortawesome/free-solid-svg-icons/faHeadset"
import { faLocationDot } from "@fortawesome/free-solid-svg-icons/faLocationDot"
import { faShield } from "@fortawesome/free-solid-svg-icons/faShield"
import { faCalendar } from "@fortawesome/free-regular-svg-icons/faCalendar"
import { faHeart } from "@fortawesome/free-regular-svg-icons/faHeart"

import { useUser } from "~config/user-provider"
import { useAuth } from "~utils/auth-hooks"
import {
    NAVIGATION_URLS,
    navigateToGuidetechPage,
    navigateToAccountPage,
    navigateToHelpPage,
    navigateToInboxPage,
    navigateToTripsPage,
    navigateToWishlistsPage,
} from "~utils/navigation-helpers"
import COPY from "~config/copy-constants"

export function useFirstSectionButtonConfigs() {
    return [
        {
            label: COPY.TRIPS,
            icon: faLocationDot,
            onClick: navigateToTripsPage,
            isBold: true,
        },
        {
            label: COPY.INBOX,
            icon: faBoxArchive,
            onClick: navigateToInboxPage,
            isBold: true,
        },
        {
            label: COPY.WISHLISTS,
            icon: faHeart,
            onClick: navigateToWishlistsPage,
            isBold: true,
        },
    ]
}

export function useSecondSectionButtonConfigs() {
    const { shouldSeeNewOutfitterCta } = useUser()

    return [
        {
            label: COPY.ACCOUNT,
            onClick: navigateToAccountPage,
        },
        ...(shouldSeeNewOutfitterCta
            ? [
                  {
                      label: COPY.BECOME_AN_OUTFITTER,
                      onClick: navigateToGuidetechPage,
                  },
              ]
            : []),
        {
            label: COPY.HELP,
            icon: faHeadset,
            onClick: navigateToHelpPage,
        },
    ]
}

export function useThirdSectionButtonConfigs() {
    const { logout } = useAuth()
    const { isAdmin, isOutfitter } = useUser()

    const conditionalButtonConfigs = []
    const externalLinkUrl = NAVIGATION_URLS.EXTERNAL.ADMIN_DASHBOARD

    if (isAdmin) {
        conditionalButtonConfigs.push({
            label: COPY.ADMIN_DASHBOARD,
            icon: faShield,
            externalLinkUrl,
        })
    }

    if (isOutfitter) {
        conditionalButtonConfigs.push({
            label: COPY.MANAGE_LISTINGS,
            icon: faCalendar,
            externalLinkUrl,
        })
    }

    return [
        ...conditionalButtonConfigs,
        {
            label: COPY.LOG_OUT,
            icon: faArrowRightFromBracket,
            onClick: () => logout(),
        },
    ]
}
