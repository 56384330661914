import type { PropsWithChildren } from "react"
import React, { useEffect, useState } from "react"
import type { WrapRootElementNodeArgs } from "gatsby"
import {
    Spinner,
    useSetColorMode,
    useIsDarkMode,
} from "@mallardbay/lib-react-components"

import ApolloProvider from "~apollo/provider"
import { UserProvider } from "~config/user-provider"
import { ResponsiveProvider } from "~config/responsive-provider"
import { AUTH_REDIRECT_ROUTE } from "~config/constants"
import { initBuilderIo } from "~utils/builder-io/builder-io-helpers"
import CourierInboxProvider from "~config/courier-inbox-provider"
import Auth0Provider from "~config/auth0-provider"
import { GlobalNavigationProvider } from "~config/global-navigation-provider"
import { ThemeProvider } from "~config/theme-provider"

const STATIC_CONTENT_OVERLAY_CLASS = "static-content-overlay"

initBuilderIo()

export function WrapRootElementSsr(props: Readonly<WrapRootElementNodeArgs>) {
    // Do not render static content to prevent white flash on dark mode.
    // This overlay is set to hide for light mode
    return (
        <>
            <div
                className={STATIC_CONTENT_OVERLAY_CLASS}
                suppressHydrationWarning
            >
                <ThemeProvider>
                    <Spinner />
                </ThemeProvider>
            </div>
            <ThemeProvider>
                <WrapRootElementCore redirectUri="/" {...props} />
            </ThemeProvider>
        </>
    )
}

export function WrapRootElementBrowser(
    props: Readonly<WrapRootElementNodeArgs>
) {
    // eslint-disable-next-line ssr-friendly/no-dom-globals-in-react-fc -- TODO does this need to be fixed?
    const redirectUri = `${window.location.origin}${AUTH_REDIRECT_ROUTE}`

    // ThemseProvider needs to contain `BrowserWrapper`
    // so we have it outside of `WrapRootElementCore`
    return (
        <ThemeProvider>
            <BrowserWrapper>
                <WrapRootElementCore redirectUri={redirectUri} {...props} />
            </BrowserWrapper>
        </ThemeProvider>
    )
}

function WrapRootElementCore({
    element,
    redirectUri,
}: Readonly<WrapRootElementNodeArgs> & { readonly redirectUri: string }) {
    return (
        <Auth0Provider redirectUri={redirectUri}>
            <GlobalNavigationProvider>
                <ApolloProvider>
                    <ResponsiveProvider>
                        <UserProvider>
                            <CourierInboxProvider>
                                {element}
                            </CourierInboxProvider>
                        </UserProvider>
                    </ResponsiveProvider>
                </ApolloProvider>
            </GlobalNavigationProvider>
        </Auth0Provider>
    )
}

function BrowserWrapper({ children }: PropsWithChildren) {
    const [isClientReady, setIsClientReady] = useState(false)

    useSetColorMode()
    const isDarkMode = useIsDarkMode()
    useEffect(() => {
        setIsClientReady(true)
    }, [])

    // Client side there's still a white flash
    // while chakra picks up the theme.
    // Using state boolean to prevent this, but
    // only if dark mode is enabled.
    if (isDarkMode && !isClientReady) return null

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>
}
