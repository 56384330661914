import React from "react"

import type { Props as CoreProps } from "~components/shared/collections/outfitters-collection"
import OutfittersCollection from "~components/shared/collections/outfitters-collection"
import { usePublicOutfitters } from "~utils/use-outfitters"
import { useOutfitterSearch } from "~utils/use-outfitter-search"
import {
    getSingleBooleanValue,
    getSingleStringValue,
    parseQueryString,
} from "~utils/search-url-query-string-helpers"
import type { SearchOutfittersForSportsmenInput } from "~graphql/generated/graphql"
import { removeUndefinedProps } from "~utils/object-helpers"
import { captureSentryException } from "~root/sentry"

type OutfitterQueryType =
    (typeof OUTFITTER_QUERY_TYPES)[keyof typeof OUTFITTER_QUERY_TYPES]

export const OUTFITTER_QUERY_TYPES = {
    ID_LIST: "ID_LIST",
    SEARCH: "SEARCH",
} as const

export interface Props extends CoreProps {
    readonly idList?: string[]
    readonly queryType: OutfitterQueryType
    readonly searchString?: string
}

export default function OutfittersCollectionQueryable(props: Props) {
    const { idList, ...genericProps } = props
    const { outfitters, isLoading } = useQueryOutfitters(props)

    return (
        <OutfittersCollection
            {...genericProps}
            outfitters={outfitters}
            isLoading={isLoading}
        />
    )
}

function useQueryOutfitters({ queryType, idList, searchString }: Props) {
    // Search
    const searchParams = getOutfitterSearchInputFromUrlQueryStringAndParse(
        String(searchString)
    )

    const skipOutfitterSearch = queryType !== OUTFITTER_QUERY_TYPES.SEARCH

    const {
        isLoading: loadingFromSearch,
        error: errorFromSearch,
        outfitters: outfittersFromSearch,
    } = useOutfitterSearch({ searchParams, skip: skipOutfitterSearch })

    const { loading, error, data } = usePublicOutfitters({
        idList,
        skip: queryType !== OUTFITTER_QUERY_TYPES.ID_LIST,
    })

    const outfittersFromIdList = data?.publicOutfitters || []

    return {
        isLoading: loading || loadingFromSearch,
        error: error || errorFromSearch,
        outfitters: [...outfittersFromIdList, ...outfittersFromSearch],
        data,
    }
}

function getOutfitterSearchInputFromUrlQueryStringAndParse(qString: string) {
    const parsedParams = parseQueryString({
        query: qString,
        onError: captureSentryException,
    })

    return mapSearchParamsToOutfitterSearchInput(parsedParams)
}

function mapSearchParamsToOutfitterSearchInput(
    searchParams: SearchQueryParams
) {
    return mapOutfitterSearchQueryObjectToSearchParams(searchParams)
}

function mapOutfitterSearchQueryObjectToSearchParams(
    searchParams: SearchQueryParams
): SearchQueryParams {
    const { state, country, isFeatured, text, status } = searchParams

    const searchQueryParams: SearchQueryParams = {
        state: getSingleStringValue(state) || undefined,
        country: getSingleStringValue(country) || undefined,
        isFeatured: getSingleBooleanValue(isFeatured) || undefined,
        text: getSingleStringValue(text) || undefined,
        status,
    }

    return removeUndefinedProps(searchQueryParams)
}

type SearchQueryParams = SearchOutfittersForSportsmenInput
