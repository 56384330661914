import React from "react"
import type { WrapPageElementNodeArgs } from "gatsby"

import Layout from "~components/shared/layout"

export function WrapPageElementSsr(wrapProps: WrapPageElementNodeArgs) {
    return <WrapPageElementCore {...wrapProps} />
}

export function WrapPageElementBrowser(wrapProps: WrapPageElementNodeArgs) {
    return <WrapPageElementCore {...wrapProps} />
}

function WrapPageElementCore({ element, props }: WrapPageElementNodeArgs) {
    return <Layout {...props}>{element}</Layout>
}
