import React from "react"
import {
    Box,
    componentStyles,
    Link,
    TextSmall,
} from "@mallardbay/lib-react-components"

import type {
    ListingOutfitterFieldsFragment,
    PublicOutfitterFieldsFragment,
    ListingPublicOutfitterFieldsFragment,
} from "~graphql/generated/graphql"

interface Props {
    readonly outfitter?:
        | ListingOutfitterFieldsFragment
        | PublicOutfitterFieldsFragment
        | ListingPublicOutfitterFieldsFragment
    readonly namePrefix?: string
    readonly shouldLinkToOutfitter?: boolean
}

export default function OutfitterLink({
    outfitter,
    namePrefix = "",
    shouldLinkToOutfitter,
}: Props) {
    const styles = useStyles()
    if (!outfitter) return null

    if (shouldLinkToOutfitter) {
        return (
            <Box style={styles.container}>
                <TextSmall>{namePrefix}</TextSmall>
                <Link style={styles.link} to={`/${outfitter.slug}`}>
                    {outfitter.name}
                </Link>
            </Box>
        )
    }

    return (
        <TextSmall>
            {namePrefix} {outfitter.name}
        </TextSmall>
    )
}

function useStyles() {
    return componentStyles({
        container: {
            display: "flex",
        },
        link: {
            fontSize: "14px",
            textDecoration: "dotted underline",
            marginLeft: 5,
        },
    })
}
