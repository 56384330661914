import React from "react"
import pluralize from "pluralize"

import COPY_CONSTANTS from "~config/copy-constants"
import { TEST_IDS } from "~config/test-ids"
import type {
    AllOutfittersFieldsFragment,
    OutfitterFieldsFragment,
    PublicOutfitterFieldsFragment,
} from "~graphql/generated/graphql"
import ImageCard from "~components/shared/todo-lib-react-components/image-card/image-card"
import ContainerWithRating from "~components/shared/price-and-rating/container-with-rating"
import { getShortLocation } from "~utils/helpers"
import { getOutfitterRoute } from "~utils/navigation-helpers"

export type OutfitterForImageCard =
    | OutfitterFieldsFragment
    | PublicOutfitterFieldsFragment
    | AllOutfittersFieldsFragment

export interface Props {
    readonly isLoading?: boolean
    readonly outfitter?: OutfitterForImageCard
}

export default function OutfitterImageCard({ outfitter, isLoading }: Props) {
    const locationString = getShortLocation(outfitter?.location)
    const imageCardProps = getCardImageProps({ outfitter, isLoading })

    return (
        <ImageCard {...imageCardProps}>
            <ContainerWithRating
                reviewAggregation={outfitter?.booking_reviews_aggregation}
            >
                {locationString}
            </ContainerWithRating>
        </ImageCard>
    )
}

// Exported for tests
export function getCardImageProps({ outfitter, isLoading }: Props) {
    const pillLabel = outfitter ? getPillLabel(outfitter.listings_count) : ""
    const linkTo = outfitter ? getOutfitterRoute(outfitter.slug) : null

    return {
        isLoading,
        title: outfitter?.name ?? "",
        linkTo,
        headerImageSrc: outfitter?.photos[0]?.url,
        headerImageAlt: COPY_CONSTANTS.OUTFITTER_IMAGE,
        pillLabel,
        testId: TEST_IDS.OUTFITTER_IMAGE_CARD,
    }
}

function getPillLabel(listingCount = 0) {
    const tripOrTrips = pluralize(COPY_CONSTANTS.TRIP_LOWER, listingCount, true)

    return `${tripOrTrips} ${COPY_CONSTANTS.LISTED}`
}
