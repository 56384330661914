import type { ComponentProps } from "react"
import React from "react"
import {
    Box,
    Tooltip,
    UploadCareImageVariant,
} from "@mallardbay/lib-react-components"

import OutfitterLink from "~components/shared/outfitter-link/outfitter-link"
import COPY_CONSTANTS from "~config/copy-constants"
import type {
    ListingsFieldsFragment,
    SearchListingFieldsFragment,
    PublicListingFieldsFragment,
    OutfitterListingFieldsFragment,
    PublicSearchListingFieldsFragment,
    ListingMinimalFieldsFragment,
    PublicListingFieldsWithoutOutfitterFragment,
} from "~graphql/generated/graphql"
import { ListingType } from "~graphql/generated/graphql"
import type { Props as ImageCardProps } from "~components/shared/todo-lib-react-components/image-card/image-card"
import ImageCard from "~components/shared/todo-lib-react-components/image-card/image-card"
import ContainerWithRating from "~components/shared/price-and-rating/container-with-rating"
import Price from "~components/shared/price-and-rating/price"
import {
    getShortLocation,
    getListingTypeCamelcase,
    doesListingHaveAvailability,
} from "~utils/helpers"
import WishlistButton from "~components/pages/wishlist/wishlist-button"
import { getListingRoute } from "~utils/navigation-helpers"
import { TEST_IDS } from "~config/test-ids"

export type ListingForImageCard =
    | ListingsFieldsFragment
    | ListingMinimalFieldsFragment
    | SearchListingFieldsFragment
    | PublicListingFieldsFragment
    | PublicSearchListingFieldsFragment
    | (OutfitterListingFieldsFragment & { outfitter: undefined })
    | (PublicListingFieldsWithoutOutfitterFragment & {
          outfitter?: undefined
      })

export interface Props {
    readonly isLoading?: boolean
    readonly listing?: ListingForImageCard
    readonly additionalImageCardProps?: Partial<ImageCardProps>
    readonly shouldLinkToOutfitter?: boolean
}

export default function ListingImageCard({
    listing,
    isLoading,
    additionalImageCardProps,
    shouldLinkToOutfitter = true,
}: Props) {
    const imageCardProps = getCardImageProps({ listing, isLoading })
    const reviewAggregation = listing?.booking_reviews_aggregation
    const prefix = `${COPY_CONSTANTS.BY_LOWER} `
    const hasAvailability = doesListingHaveAvailability(listing)

    const tooltipLabel = hasAvailability
        ? null
        : COPY_CONSTANTS.NO_AVAILABILITY_DESCRIPTION

    return (
        <Tooltip label={tooltipLabel}>
            {/* tooltip's direct child must take a ref */}
            <Box>
                <ImageCard
                    {...imageCardProps}
                    {...additionalImageCardProps}
                    uploadCareImageVariant={
                        UploadCareImageVariant.MD_HORIZONTAL
                    }
                >
                    <OutfitterLink
                        outfitter={listing?.outfitter}
                        namePrefix={prefix}
                        shouldLinkToOutfitter={shouldLinkToOutfitter}
                    />
                    <ContainerWithRating reviewAggregation={reviewAggregation}>
                        {listing?.lowest_pricing_package && (
                            <Price isLoading={isLoading} listing={listing} />
                        )}
                    </ContainerWithRating>
                </ImageCard>
            </Box>
        </Tooltip>
    )
}

// Exported for tests
export function getCardImageProps({ listing, isLoading }: Props) {
    const title = listing?.title ?? ""
    const imageSrc = listing?.cover_image ?? ""
    const aboveTitleText = getAboveTitleText(listing)
    const hasAvailability = doesListingHaveAvailability(listing)

    const pillLabel = hasAvailability ? null : COPY_CONSTANTS.UNAVAILABLE

    const topRightCornerContent = listing ? (
        <WishlistButton listingId={listing.id} />
    ) : null

    const linkTo = listing ? getListingRoute(listing.id) : null

    return {
        isLoading: isLoading,
        aboveTitleText: aboveTitleText,
        title: title,
        headerImageSrc: imageSrc,
        headerImageAlt: COPY_CONSTANTS.LISTING_IMAGE,
        linkTo: linkTo,
        topRightCornerContent: topRightCornerContent,
        testId: TEST_IDS.LISTING_IMAGE_CARD,
        pillLabel,
        style: {
            opacity: hasAvailability ? 1 : 0.6,
        },
    } as const satisfies ComponentProps<typeof ImageCard>
}

function getAboveTitleText(listing?: ListingForImageCard) {
    if (!listing) return ""

    const listingType = listing.type ?? ListingType.BlastCast
    const listingTypeDisplay = getListingTypeCamelcase(listingType)
    const locationText = getShortLocation(listing.location)

    if (!locationText) return listingTypeDisplay

    const parts = [listingTypeDisplay, COPY_CONSTANTS.IN, locationText]

    return parts.join(" ")
}
