import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUser } from "@fortawesome/free-regular-svg-icons/faUser"

import { useAuth } from "~utils/auth-hooks"
import COPY from "~config/copy-constants"
import { NAVIGATION_URLS, useIsAccountRoute } from "~utils/navigation-helpers"
import { Size } from "~components/shared/todo-lib-react-components/avatar"

import CurrentUserAvatar from "../current-user-avatar"

import TabBarItem, { ICON_SIZE } from "./mobile-tab-bar-item"

export default function MobileTabBarItemProfile() {
    const isAccountRoute = useIsAccountRoute()
    const { isAuthenticated, loginWithRedirect } = useAuth()

    const title = isAuthenticated ? COPY.ACCOUNT : COPY.SIGN_IN
    const onClick = isAuthenticated ? undefined : loginWithRedirect
    const to = isAuthenticated ? NAVIGATION_URLS.INTERNAL.ACCOUNT : undefined

    return (
        <TabBarItem
            title={title}
            isActive={Boolean(isAccountRoute)}
            onClick={onClick}
            to={to}
        >
            {isAuthenticated && <CurrentUserAvatar size={Size.XSmall} />}
            {!isAuthenticated && (
                <FontAwesomeIcon
                    style={{ fontSize: ICON_SIZE }}
                    icon={faUser}
                />
            )}
        </TabBarItem>
    )
}
