export const INPUTS = {
    COLLECTION_TYPE: "type",
    LABEL: "label",
    BUTTON_LABEL: "buttonLabel",
    BUTTON_URL: "buttonUrl",
    QUERY_TYPE: "queryType",
    ID_LIST: "idList",
    SEARCH_STRING: "searchString",
    STATE: "state",
    ID_MAPS: "idMaps",
    ID: "id",
    ACCORDION_ITEMS: "accordionItems",
    TITLE: "title",
    DESCRIPTION: "description",
}

export const FRIENDLY_INPUT_NAMES = {
    COLLECTION_TYPE: "Collection type",
}

export const INPUT_TYPES = {
    STRING: "string",
    LIST: "list",
}

export const DEFAULT_VALUES = {
    LABEL: "Collection",
    BUTTON_LABEL: "See more",
    STATE: "Texas",
    COLLECTION_LABEL_LISTINGS: "Listings Collection",
    COLLECTION_LABEL_OUTFITTERS: "Outfitters Collection",
    // eslint-disable-next-line no-secrets/no-secrets
    SEARCH_STRING_LISTINGS: "query=hunt&species=afnfYLAPqbXI&type=HUNTING",
    SEARCH_STRING_OUTFITTERS: "state=texas&isFeatured=true&text=ducks",
}

export const BUILDER_CUSTOM_COMPONENTS = {
    PRIMARY_BUTTON: "Primary Button",
    OUTFITTERS_COLLECTION: "Outfitters Collection",
    LISTINGS_COLLECTION: "Listings Collection",
    ACCORDION: "Accordion",
    PRICING_TABLE: "Pricing Table",
}

export const EDITOR_SETTINGS_KEY = "editor.settings"
