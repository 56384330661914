import React, { type PropsWithChildren } from "react"
import { Box } from "@mallardbay/lib-react-components"

import Modal from "~components/shared/todo-lib-react-components/modal"
import type { ModalProps } from "~components/shared/todo-lib-react-components/modal"

interface MbModalProps extends PropsWithChildren, Pick<ModalProps, "size"> {
    readonly title: string
    readonly isOpen: boolean
    readonly onClose: () => void
    readonly allowClose?: boolean
    readonly noPadding?: boolean
}

// TODO: Delete once all other modals don't use it
export default function MbModal({
    title,
    isOpen,
    onClose,
    allowClose = true,
    children,
    noPadding = false,
    size = { base: "sm", sm: "md" },
}: MbModalProps) {
    return (
        <Modal
            title={title}
            shouldShowCloseButton={allowClose}
            shouldPreventClose={!allowClose}
            shouldAddPadding={!noPadding}
            size={size}
            isOpen={isOpen}
            onClose={onClose}
        >
            <Box>{children}</Box>
        </Modal>
    )
}
