import React from "react"
import { Inbox } from "@trycourier/react-inbox"
import { useColors } from "@mallardbay/lib-react-components"

import { useAuth } from "~utils/auth-hooks"

import CourierInboxMobileAnchor from "./courier-inbox-mobile-anchor"

interface Props {
    readonly useMobileAnchor?: boolean
}

export default function CourierInbox({ useMobileAnchor = false }: Props) {
    const { isAuthenticated } = useAuth()

    const brandColors = useBrandColors()

    if (!isAuthenticated) {
        return null
    }

    return (
        <Inbox
            brand={brandColors}
            // if mobile view replaces the default bell icon with our custom menu item
            renderBell={useMobileAnchor ? CourierInboxMobileAnchor : undefined}
            // hides footer saying 'provided by courier'
            renderFooter={() => null}
        />
    )
}

function useBrandColors() {
    const colors = useColors()

    return {
        colors: {
            primary: colors.primaryButtonColor,
            secondary: colors.borderColorDark,
            tertiary: colors.borderColor,
        },
    }
}
