import { tryWithDelay } from "@mallardbay/lib-shared-helpers"

import { getVersionNumber } from "~utils/helpers"
import COPY_CONSTANTS from "~config/copy-constants"

const SENTRY_COPY = COPY_CONSTANTS.SENTRY

export function captureSentryException(error: unknown, options?: unknown) {
    tryWithDelay({
        ...getTryWithDelaySharedParams(),
        captureException: (err) =>
            console.error(SENTRY_COPY.UNABLE_TO_CAPTURE_ERROR, err),
        functionToCall: () => {
            return captureSentryExceptionCore(error, options)
        },
    })
}

function captureSentryExceptionCore(error: unknown, options?: unknown) {
    const isDisabled = isSentryDisabled()
    // If disabled, console log the error
    if (isDisabled) {
        console.error(error)
        return true
    }

    const Sentry = getSentry()
    if (!Sentry) {
        console.error(new Error(SENTRY_COPY.CAPTURED_ERROR_BEFORE_SENTRY))
        console.error(error)
        return false
    }

    Sentry.captureException(error, options)
    return true
}

export async function initSentry() {
    await tryWithDelay({
        ...getTryWithDelaySharedParams(),
        captureException: (err) =>
            console.error(SENTRY_COPY.UNABLE_TO_INIT_SENTRY, err),
        functionToCall: () => {
            return initSentryCore()
        },
    })
}

function initSentryCore() {
    const isDisabled = isSentryDisabled()
    // If disabled or ssr, just skip any init
    if (typeof window === "undefined" || isDisabled) return true

    const Sentry = getSentry()

    if (!Sentry) {
        console.error(SENTRY_COPY.SENTRY_NOT_AVAILABLE_YET)
        return false
    }

    Sentry.init({
        dsn: process.env.GATSBY_SENTRY_DSN,
        autoSessionTracking: true,
        environment: process.env.NODE_ENV,
        release: getVersionNumber(),
        tracesSampleRate: 1.0,
    })

    console.log(SENTRY_COPY.SENTRY_DID_INIT)
    return true
}

function getSentry() {
    if (typeof window !== "undefined" && window.Sentry) {
        return window.Sentry
    } else {
        console.warn(SENTRY_COPY.SENTRY_NOT_AVAILABLE_YET)
        return null
    }
}

export function isSentryDisabled() {
    return process.env.GATSBY_IS_SENTRY_DISABLED === "true"
}

function getTryWithDelaySharedParams() {
    return {
        attemptNumber: 1,
        delay: 200,
        errorMessage: SENTRY_COPY.UNABLE_TO_INIT_SENTRY,
        hasSucceeded: false,
        maxAttempts: 10,
    }
}
