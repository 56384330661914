import type { PropsWithChildren } from "react"
import React from "react"
import {
    componentStyles,
    ResponsiveSlider,
} from "@mallardbay/lib-react-components"

import CollectionButton from "~components/shared/page-sections/entity-card-collection/collection-button"
import Box from "~components/shared/todo-lib-react-components/box"

interface Props extends PropsWithChildren {
    readonly label?: string
    readonly buttonLabel?: string | null
    readonly buttonUrl?: string | null
    readonly onSwipe?: () => void
}

export default function GenericCollectionSlider({
    label,
    buttonLabel,
    buttonUrl,
    children,
    onSwipe,
}: Props) {
    const styles = useStyles()

    return (
        <>
            <ResponsiveSlider title={label} onSwipe={onSwipe}>
                {children}
            </ResponsiveSlider>
            <Box style={styles.buttonContainer}>
                <CollectionButton label={buttonLabel} href={buttonUrl} />
            </Box>
        </>
    )
}

function useStyles() {
    return componentStyles({
        buttonContainer: {
            textAlign: "center",
            marginTop: 6,
        },
    })
}
