import type { PropsWithChildren } from "react"
import React from "react"
import { Link } from "gatsby-link"
import { useColors, componentStyles } from "@mallardbay/lib-react-components"

import Box from "../todo-lib-react-components/box"
import { TextExtraSmall } from "../todo-lib-react-components/text"

interface Props extends PropsWithChildren {
    readonly isActive: boolean
    readonly title: string
    readonly onClick?: () => void
    readonly to?: string
}

export const ICON_SIZE = "18px"

export default function MobileTabBarItem({
    isActive,
    title,
    to,
    onClick,
    children,
}: Props) {
    const styles = useStyles(isActive)
    const asTo = to
        ? {
              as: Link,
              to,
          }
        : {}

    return (
        <Box style={styles.container} {...asTo} onClick={onClick}>
            {children}

            <TextExtraSmall style={styles.title}>{title}</TextExtraSmall>
        </Box>
    )
}

function useStyles(isActive: boolean) {
    const colors = useColors()

    const color = isActive ? colors.brandDefault : colors.text
    return componentStyles({
        container: {
            color,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        },
        title: {
            color,
            marginTop: "6px",
        },
    })
}
