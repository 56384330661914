import React from "react"

import type { Props as ButtonProps } from "~components/shared/header/header-profile-menu-button"
import HeaderProfileMenuButton from "~components/shared/header/header-profile-menu-button"

interface Props {
    readonly configs: ButtonProps[]
}

export default function HeaderProfileMenuButtons({ configs }: Props) {
    return (
        <>
            {configs.map(
                ({ label, icon, onClick, isBold, externalLinkUrl }) => (
                    <HeaderProfileMenuButton
                        key={label}
                        label={label}
                        icon={icon}
                        onClick={onClick}
                        externalLinkUrl={externalLinkUrl}
                        isBold={isBold}
                    />
                )
            )}
        </>
    )
}
