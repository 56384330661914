import {
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    Modal as ModalOg,
    ModalOverlay,
    ModalBody,
    ModalFooter,
} from "@chakra-ui/react"
import React from "react"
import type {
    BoxProps,
    ModalCloseButtonProps,
    ModalContentProps,
    ModalProps as ModalPropsOg,
} from "@chakra-ui/react"
import type { StyleProps } from "@mallardbay/lib-react-components"
import { useTheme } from "@mallardbay/lib-react-components"

export interface ModalProps
    extends Pick<ModalPropsOg, "size" | "scrollBehavior" | "children"> {
    readonly title: string
    readonly shouldShowCloseButton?: boolean
    readonly shouldPreventClose?: boolean
    readonly shouldAddPadding?: boolean
    readonly isOpen: boolean
    readonly onClose: () => void
    readonly style?: StyleProps
    readonly modalBgColor?: string
    readonly FooterComponent?: React.ReactNode
}

// TODO: Delete once all other modals don't use it
export default function Modal({
    shouldShowCloseButton = true,
    shouldPreventClose = false,
    isOpen,
    onClose,
    scrollBehavior,
    size = "lg",
    title,
    children,
    modalBgColor,
    shouldAddPadding,
    FooterComponent,
}: ModalProps) {
    const styles = useStyleProps({ shouldAddPadding, modalBgColor })

    return (
        <ModalOg
            size={size}
            scrollBehavior={scrollBehavior}
            isOpen={isOpen}
            onClose={onClose}
            isCentered
            closeOnOverlayClick={!shouldPreventClose}
        >
            <ModalOverlay />
            <ModalContent {...styles.modalContent}>
                {shouldShowCloseButton && !shouldPreventClose && (
                    <ModalCloseButton {...styles.closeButton} />
                )}
                {title && <ModalHeader>{title}</ModalHeader>}
                <ModalBody {...styles.modalBody}>{children}</ModalBody>
                {FooterComponent && (
                    <ModalFooter sx={styles.footer}>
                        {FooterComponent}
                    </ModalFooter>
                )}
            </ModalContent>
        </ModalOg>
    )
}

function useStyleProps({
    shouldAddPadding,
    modalBgColor,
}: {
    shouldAddPadding?: boolean
    modalBgColor?: string
}) {
    const { colors, zIndices } = useTheme()
    const contentPadding = shouldAddPadding ? 6 : 0

    const closeButton: ModalCloseButtonProps = { top: 4 }
    const modalContent: ModalContentProps = {
        rounded: "xl",
        zIndex: zIndices.modal,
        bg: modalBgColor || colors.bgColor,
    }
    const modalBody: BoxProps = {
        mt: 4,
        paddingX: contentPadding,
        paddingBottom: contentPadding,
    }

    return {
        closeButton,
        modalContent,
        modalBody,
        footer: {
            borderTopWidth: "1px",
            borderTopStyle: "solid",
            borderTopColor: colors.borderOpaque,
        },
        footerButtons: {
            width: "100%",
        },
    }
}
