import React from "react"
import { useLocation, useMatch } from "@reach/router"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBoxArchive } from "@fortawesome/free-solid-svg-icons/faBoxArchive"
import { faHome } from "@fortawesome/free-solid-svg-icons/faHome"
import { faLocationDot } from "@fortawesome/free-solid-svg-icons/faLocationDot"
import { faUserPlus } from "@fortawesome/free-solid-svg-icons/faUserPlus"
import { faHeart } from "@fortawesome/free-regular-svg-icons/faHeart"
import type { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { useColors } from "@mallardbay/lib-react-components"

import Box from "~components/shared/todo-lib-react-components/box"
import MobileTabBarItemProfile from "~components/shared/mobile-tab-bar/mobile-tab-bar-item-profile"
import {
    useMobileMediaQueries,
    useTabletMediaQueries,
} from "~components/shared/todo-lib-react-components/hooks/use-media-queries"
import { TEST_IDS } from "~config/test-ids"
import { useGlobalNavigation } from "~config/global-navigation-provider"
import { useAuth } from "~utils/auth-hooks"
import COPY from "~config/copy-constants"
import { NAVIGATION_URLS } from "~utils/navigation-helpers"

import MobileTabBarItem, { ICON_SIZE } from "./mobile-tab-bar-item"

interface ItemConfig {
    title: string
    icon: IconDefinition
    isActive: boolean
    to?: string
    isHidden?: boolean
    onClick?: () => void
}

export default function MobileTabBar() {
    const styles = useStyles()

    const isTabBarHidden = useShouldHideTabBar()
    const itemConfigs = useItemConfigs()

    const filteredItemConfigs = itemConfigs.filter((config) => !config.isHidden)

    if (isTabBarHidden) return null

    return (
        <Box style={styles.container} testId={TEST_IDS.MOBILE_TAB_BAR}>
            {filteredItemConfigs.map((config) => (
                <MobileTabBarItem
                    key={config.title}
                    title={config.title}
                    to={config.to}
                    isActive={config.isActive}
                    onClick={config.onClick}
                >
                    <FontAwesomeIcon style={styles.icon} icon={config.icon} />
                </MobileTabBarItem>
            ))}
            <MobileTabBarItemProfile />
        </Box>
    )
}

function useStyles() {
    const colors = useColors()
    const height = "68px"

    return {
        container: {
            height,
            position: "fixed",
            bottom: 0,
            width: "100vw",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-around",
            userSelect: "none",
            backgroundColor: colors.bgColor,
            borderTop: `1px solid ${colors.borderOpaque}`,
        },
        icon: {
            fontSize: ICON_SIZE,
        },
    }
}

function useShouldHideTabBar() {
    const { pathname } = useLocation()
    const { isMobileOnly } = useMobileMediaQueries()
    const { isTabletOnly } = useTabletMediaQueries()
    // If we hide the footer, we also wanna hide the mobile tab bar
    const { isMobileTabBarHidden } = useGlobalNavigation()
    if (isMobileTabBarHidden) return true

    // Do it this way so we hie it by default when rendered server side
    if (!isTabletOnly && !isMobileOnly) return true

    return [
        NAVIGATION_URLS.INTERNAL.LISTING,
        NAVIGATION_URLS.INTERNAL.BOOK,
        NAVIGATION_URLS.INTERNAL.INBOX,
        NAVIGATION_URLS.INTERNAL.GROUP_PAY_MEMBER,
        NAVIGATION_URLS.INTERNAL.CHECKOUT_SUMMARY,
    ].some((path) => pathname.includes(path))
}

function useItemConfigs(): ItemConfig[] {
    const { pathname } = useLocation()
    const isExploreActive = [
        NAVIGATION_URLS.INTERNAL.HOME,
        NAVIGATION_URLS.INTERNAL.SEARCH,
    ].includes(pathname)
    const isWishlistsActive = useMatch(
        `${NAVIGATION_URLS.INTERNAL.WISHLISTS}/*`
    )
    const isTripsActive = useMatch(`${NAVIGATION_URLS.INTERNAL.TRIPS}/*`)
    const isInboxActive = useMatch(`${NAVIGATION_URLS.INTERNAL.INBOX}/*`)

    const { isAuthenticated, loginWithRedirect } = useAuth()
    const handleSignup = () => loginWithRedirect({ isSignup: true })

    return [
        {
            title: COPY.EXPLORE,
            icon: faHome,
            isActive: Boolean(isExploreActive),
            to: NAVIGATION_URLS.INTERNAL.HOME,
        },
        {
            title: COPY.WISHLISTS,
            icon: faHeart,
            isActive: Boolean(isWishlistsActive),
            to: NAVIGATION_URLS.INTERNAL.WISHLISTS,
            isHidden: !isAuthenticated,
        },
        {
            title: COPY.TRIPS,
            icon: faLocationDot,
            isActive: Boolean(isTripsActive),
            to: NAVIGATION_URLS.INTERNAL.TRIPS,
        },
        {
            title: COPY.INBOX,
            icon: faBoxArchive,
            isActive: Boolean(isInboxActive),
            to: NAVIGATION_URLS.INTERNAL.INBOX,
            isHidden: !isAuthenticated,
        },
        {
            title: COPY.SIGN_UP,
            icon: faUserPlus,
            isActive: false,
            onClick: handleSignup,
            isHidden: isAuthenticated,
        },
    ]
}
