import type { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { Box, useTheme } from "@mallardbay/lib-react-components"
import type { ReactNode } from "react"
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { TEST_IDS } from "~config/test-ids"

interface Props {
    readonly title: string
    readonly isActive?: boolean
    readonly icon?: IconDefinition
    readonly renderIcon?: () => ReactNode
}

export default function AccountNavigatorItem({
    title,
    icon,
    renderIcon,
    isActive,
}: Props) {
    const styles = useStyles({ isActive })

    return (
        <Box style={styles.root}>
            {title}
            <Box style={styles.icon}>
                {icon && <FontAwesomeIcon icon={icon} />}
                {renderIcon?.()}
            </Box>

            {isActive && (
                <Box
                    testId={TEST_IDS.ACTIVE_INDICATOR}
                    style={styles.indicator}
                />
            )}
        </Box>
    )
}

function useStyles({ isActive }: { isActive?: boolean }) {
    const { colors } = useTheme()

    return {
        root: {
            flex: 1,
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingLeft: "16px",
            color: isActive ? colors.contentPrimary : colors.contentSecondary,
            fontWeight: isActive ? 700 : 400,
            ":hover": {
                backgroundColor: colors.backgroundSecondary,
            },
        },
        icon: {
            fontSize: "18px",
            marginRight: "10px",
            color: colors.contentPrimary,
        },
        indicator: {
            width: "6px",
            height: "100%",
            background: colors.primaryButtonColor,
        },
    }
}
