import "~styles/global"

import { initSentry } from "~root/sentry"
import { WrapRootElementBrowser } from "~utils/wrap-root-element"
import { WrapPageElementBrowser } from "~utils/wrap-page-element"

// Initialize Sentry dynamically
async function onClientEntry() {
    await initSentry()
}

export {
    // eslint-disable-next-line import/no-unused-modules -- imported by gatsby
    WrapRootElementBrowser as wrapRootElement,
    // eslint-disable-next-line import/no-unused-modules -- imported by gatsby
    WrapPageElementBrowser as wrapPageElement,
    // replaceHydrateFunction,
    // eslint-disable-next-line import/no-unused-modules -- imported  by gatsby
    onClientEntry,
}

// Returning null in replaceHydrateFunction allows us to see what
// the server renders without hydration
// function replaceHydrateFunction() {
//     return (element, container) => {
//         return null
//     }
// }
