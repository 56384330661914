import React, { useState } from "react"
import { navigate } from "gatsby-link"
import queryString from "query-string"

import InputSelect from "~components/shared/todo-lib-react-components/input-select/input-select"
import useSearchSuggestionsForTrip from "~utils/use-search-suggestions-for-trip"
import { useDebounceString } from "~utils/use-debounce-string"
import type { InputSelectItem } from "~components/shared/todo-lib-react-components/input-select/input-select.types"
import { SearchSuggestionItemTypes } from "~config/constants"
import {
    getSearchQueryParams,
    getUrlQueryParamsString,
} from "~utils/url-param-helpers"
import { useIsOnSearchPage } from "~utils/hooks/use-is-on-route"
import { useSearchHistory } from "~utils/use-search-history"
import type { SearchQueryParams } from "~utils/search-url-query-string-helpers"

export default function SearchBar() {
    const [text, setText] = useState("")
    const [debouncedText] = useDebounceString(text)
    const searchParams = {
        query: debouncedText || text,
    }

    // don't include history once the user starts typing
    const shouldIncludeSearchHistory = Boolean(!debouncedText.length)
    const { searchSuggestions, isLoading, error } = useSearchSuggestionsForTrip(
        {
            text: debouncedText,
            shouldIncludeSearchHistory,
        }
    )
    const handleSearch = useHandleSearch()

    return (
        <InputSelect
            itemGroups={searchSuggestions}
            isLoading={isLoading}
            error={error}
            onChange={(newValue) => {
                setText(newValue)
            }}
            onSelection={(item) => {
                handleOnSelection({
                    item,
                    handleSearch,
                })
            }}
            onSearch={() => handleSearch(searchParams)}
        />
    )
}

function handleOnSelection({
    item,
    handleSearch,
}: {
    item: InputSelectItem
    handleSearch: (searchQueryParams: SearchQueryParams) => void
}) {
    const searchQueryParams: SearchQueryParams = {}

    switch (item.type) {
        case SearchSuggestionItemTypes.State:
            searchQueryParams.state = item.value
            break
        case SearchSuggestionItemTypes.Country:
            searchQueryParams.country = item.value
            break
        case SearchSuggestionItemTypes.Species:
            searchQueryParams.species = [item.value]
            break
        default:
            searchQueryParams.query = item.value
            break
    }
    handleSearch(searchQueryParams)
}

function useHandleSearch() {
    const urlQueryParamsString = getUrlQueryParamsString()
    const currentQueryParams = getSearchQueryParams(urlQueryParamsString)

    // When searching on the search page we want to merge with existing query params
    const isOnSearchPage = useIsOnSearchPage()
    const { appendToHistory } = useSearchHistory()

    return (newSearchQueryParams: SearchQueryParams) => {
        let mergedSearchParams = {
            ...newSearchQueryParams,
        }
        if (isOnSearchPage) {
            mergedSearchParams = {
                ...currentQueryParams,
                ...newSearchQueryParams,
            }
        }
        appendToHistory(mergedSearchParams.query)
        void navigate(convertParamsToQueryString(mergedSearchParams))
    }
}

function convertParamsToQueryString(searchQueryParams: SearchQueryParams) {
    const formattedParams = {
        ...searchQueryParams,
        species:
            searchQueryParams.species && searchQueryParams.species.length > 0
                ? searchQueryParams.species.join(",")
                : undefined,
        amenities:
            searchQueryParams.amenities &&
            searchQueryParams.amenities.length > 0
                ? searchQueryParams.amenities.join(",")
                : undefined,
    }
    const searchQueryString = queryString.stringify(formattedParams)

    return `/search?${searchQueryString}`
}
