import { trackEvent as trackEventGeneric } from "@mallardbay/lib-shared-helpers"

import type {
    AddOnFormFieldsFragment,
    PricingPackageFieldsFragment,
    QuoteFieldsFragment,
} from "~graphql/generated/graphql"
import { TypeName } from "~config/constants"
import { centsToDollars } from "~utils/helpers"
import { captureSentryException } from "~root/sentry"

import type { EntityType } from "./event-tracking-constants"
import {
    ItemCategory,
    EventName,
    Currency,
    LoginMethod,
} from "./event-tracking-constants"
import type {
    AddOnItem,
    EventParams,
    Item,
    SearchTerm,
    SelectPromotionParams,
    ShareParams,
    CustomPageViewParams,
} from "./event-tracking-types"

let userId: string | null = null

// Exported for tests

// This wrapper's main function is to type more strictly.
// Use this over the core `trackEvent` from `lib-shared-helpers`
function trackEvent(eventName: EventName, eventParams: EventParams) {
    const paramsToUse = {
        ...eventParams,
        userId,
    }
    trackEventGeneric({
        eventName,
        eventParams: paramsToUse,
        onError: captureSentryException,
    })
}

// Specific events

export function trackSearch(searchTerm: SearchTerm) {
    const normalizedSearchTerm = Array.isArray(searchTerm)
        ? searchTerm.filter((term) => Boolean(term)).join(",")
        : searchTerm
    if (!normalizedSearchTerm) return
    trackEvent(EventName.SEARCH, { search_term: normalizedSearchTerm })
}

export function trackCustomPageView(location: CustomPageViewParams) {
    trackEvent(EventName.CUSTOM_PAGE_VIEW, {
        pathname: location.pathname,
        search: location.search,
        host: location.host,
    })
}

export function trackLogin(userIdLocal?: string) {
    setUserId(userIdLocal)
    trackEvent(EventName.LOGIN, { method: LoginMethod.AUTH0 })
}

// We do this so we can use it outside of hooks easily
export function setUserId(userIdLocal?: string) {
    userId = userIdLocal || null
}

export function trackLogout() {
    userId = null
    trackEvent(EventName.LOGIN, { method: LoginMethod.AUTH0 })
}

export function trackPurchase({
    quote,
    bookingId,
}: {
    quote: QuoteFieldsFragment
    bookingId: string
}) {
    const addOns = mapAddOnsToItems(quote)
    const pricingPackages = mapQuotePackagesToItems(quote)
    const total = centsToDollars(quote.total)
    const params = {
        currency: Currency.USD,
        value: total,
        transaction_id: bookingId,
        coupon: quote.coupon?.custom_code,
        internal_source: quote.attribution?.internal_source,
        items: [
            {
                item_id: bookingId,
                item_name: ItemCategory.BOOKING,
                item_category: ItemCategory.BOOKING,
                price: total,
                quantity: 1,
            } satisfies Item,
            ...pricingPackages,
            ...addOns,
        ].filter(
            (item): item is NonNullable<typeof item> => item !== null
        ) satisfies Item[],
    }
    trackEvent(EventName.PURCHASE, params)
}

export function trackBeginCheckout({
    pricingPackage,
}: {
    pricingPackage: PricingPackageFieldsFragment
}) {
    const value = centsToDollars(pricingPackage.price)
    const params = {
        currency: Currency.USD,
        value,
        items: [
            {
                item_id: pricingPackage.id,
                item_name: pricingPackage.name,
                item_category: ItemCategory.PRICING_PACKAGE,
                price: value,
            },
        ],
    }
    trackEvent(EventName.BEGIN_CHECKOUT, params)
}

export function trackSelectContent({
    entityId,
    entityType,
}: {
    entityId: string
    entityType: EntityType
}) {
    trackEvent(EventName.SELECT_CONTENT, {
        content_id: entityId,
        content_type: entityType,
    })
}

export function trackShare(params: ShareParams) {
    trackEvent(EventName.SHARE, params)
}

export function trackSelectPromotion(params: SelectPromotionParams) {
    trackEvent(EventName.SELECT_PROMOTION, params)
}

export function trackAddToCart(
    item: AddOnFormFieldsFragment | PricingPackageFieldsFragment,
    quantity: number = 1
) {
    trackEvent(EventName.ADD_TO_CART, {
        currency: Currency.USD,
        value: getPrice(item),
        items: getItems(item, quantity),
    })
}

export function trackRemoveFromCart(
    item: AddOnFormFieldsFragment | PricingPackageFieldsFragment,
    quantity: number
) {
    trackEvent(EventName.REMOVE_FROM_CART, {
        currency: Currency.USD,
        value: getPrice(item),
        items: getItems(item, quantity),
    })
}

// Helpers
function mapQuotePackagesToItems(quote: QuoteFieldsFragment) {
    const quantity = 1
    return quote.packages
        .map((quotePackage) =>
            mapItemToGtmItem(quotePackage.pricing_package, quantity)
        )
        .filter((item) => Boolean(item))
}

function mapAddOnsToItems(quote: QuoteFieldsFragment) {
    const addOns = quote.add_ons || []
    return addOns
        .map((addOn) => mapItemToGtmItem(addOn, addOn.quantity))
        .filter((item) => Boolean(item))
}

function getPrice(
    item: AddOnFormFieldsFragment | PricingPackageFieldsFragment
) {
    switch (item.__typename) {
        case TypeName.AddOn:
            return centsToDollars(item.unit_price)
        case TypeName.PricingPackage:
            return centsToDollars(item.price)
        default:
            return 0
    }
}

function getItems(
    item: AddOnFormFieldsFragment | PricingPackageFieldsFragment,
    quantity: number
): Item[] {
    const mappedItem = mapItemToGtmItem(item, quantity)
    return mappedItem ? [mappedItem] : []
}

function mapItemToGtmItem(item: AddOnItem, quantity: number) {
    const commonProps = {
        item_id: item.id,
        item_name: item.name,
        item_category3: item.price_by_day,
        item_category4: item.price_by_guest,
        quantity,
    }

    switch (item.__typename) {
        case TypeName.AddOn:
        case TypeName.QuoteAddOn:
            return {
                ...commonProps,
                item_category: ItemCategory.ADD_ON,
                ...(item.species?.name && {
                    item_category2: item.species.name,
                }),
                price: centsToDollars(item.unit_price),
            }
        case TypeName.PricingPackage:
            return {
                ...commonProps,
                item_category: ItemCategory.PRICING_PACKAGE,
                price: centsToDollars(item.price),
            }
        default:
            return null
    }
}
