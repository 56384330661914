import React, { useMemo } from "react"

import type { ListingForImageCard } from "~components/shared/todo-lib-react-components/image-card/listing-image-card"
import ListingImageCard from "~components/shared/todo-lib-react-components/image-card/listing-image-card"
import { doesListingHaveAvailability } from "~utils/helpers"

import GenericCollection from "./generic-collection"
import type { Props as GenericProps } from "./generic-collection"

export interface Props extends GenericProps {
    readonly listings?: ListingForImageCard[] | null
    readonly isLoading?: boolean
    readonly shouldLinkToOutfitter?: boolean
}

export default function ListingsCollection(props: Props) {
    const { listings, shouldLinkToOutfitter, ...genericProps } = props

    const sortedListings = useMemo(
        () => sortListingsAvailableFirst(listings),
        [listings]
    )

    return (
        <GenericCollection {...genericProps}>
            {sortedListings.map((listing) => (
                <ListingImageCard
                    key={listing.id}
                    listing={listing}
                    shouldLinkToOutfitter={shouldLinkToOutfitter}
                />
            ))}
        </GenericCollection>
    )
}

export function sortListingsAvailableFirst(
    listings: ListingForImageCard[] | null | undefined
) {
    if (!listings) return []

    return [...listings].sort((firstListing, secondListing) => {
        const aHasAvailability = doesListingHaveAvailability(firstListing)
        const bHasAvailability = doesListingHaveAvailability(secondListing)

        if (aHasAvailability && !bHasAvailability) return -1
        if (!aHasAvailability && bHasAvailability) return 1
        return 0
    })
}
