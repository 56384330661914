import React from "react"

import { usePublicListings, useSearchListings } from "~utils/use-listings"
import ListingsCollection from "~components/shared/collections/listings-collection"
import type { SearchListingsForSportsmenInput } from "~graphql/generated/graphql"
import { getSearchInputFromUrlQueryStringAndParse } from "~utils/url-param-helpers"
import type { Props as CoreProps } from "~components/shared/collections/listings-collection"

export const DEFAULT_QUERY_SIZE = 20
export const LISTING_QUERY_TYPES = {
    SEARCH: "SEARCH",
    ID_LIST: "ID_LIST",
    STATE: "STATE",
    FEATURED: "FEATURED",
} as const

type ListingQueryType =
    (typeof LISTING_QUERY_TYPES)[keyof typeof LISTING_QUERY_TYPES]

export interface Props extends CoreProps {
    readonly idList?: string[]
    readonly searchString?: string
    readonly state?: string
    readonly queryType: ListingQueryType
}

export default function ListingsCollectionQueryable(props: Props) {
    const { listings, isLoading } = useQueryListings(props)

    return (
        <ListingsCollection
            {...props}
            listings={listings}
            isLoading={isLoading}
        />
    )
}

function useQueryListings(props: Props) {
    const { queryType, idList, searchString, state } = props
    // Search
    const searchParams = getSearchInputFromUrlQueryStringForCollection({
        searchString: searchString,
        state: state,
        queryType,
    })

    const isIdListQuery = queryType === LISTING_QUERY_TYPES.ID_LIST
    const {
        isLoading: loadingFromSearch,
        error: errorFromSearch,
        listings: listingsFromSearch,
    } = useSearchListings({
        searchParams,
        skip: isIdListQuery,
    })

    // Id list
    const {
        loading: loadingFromIdList,
        error: errorFromIdList,
        data: dataFromIdList,
    } = usePublicListings({
        idList,
        skip: !isIdListQuery,
    })
    const listingsFromIdList = dataFromIdList?.publicListings || []

    return {
        isLoading: loadingFromIdList || loadingFromSearch,
        error: errorFromIdList || errorFromSearch,
        listings: [...listingsFromIdList, ...listingsFromSearch],
    }
}

function getSearchInputFromUrlQueryStringForCollection({
    searchString,
    state,
    queryType,
}: Pick<
    Props,
    "queryType" | "state" | "searchString"
>): SearchListingsForSportsmenInput {
    switch (queryType) {
        case LISTING_QUERY_TYPES.SEARCH:
            return getSearchInputFromUrlQueryStringAndParse(
                String(searchString)
            )

        case LISTING_QUERY_TYPES.STATE:
            return {
                state,
            }

        case LISTING_QUERY_TYPES.FEATURED:
            return {
                isFeatured: true,
            }

        case LISTING_QUERY_TYPES.ID_LIST:
        default:
            return {}
    }
}
