import React from "react"
import type { StyleProps } from "@mallardbay/lib-react-components"
import {
    HeadingMedium,
    componentStyles,
} from "@mallardbay/lib-react-components"

interface Props {
    readonly label?: string | null
    readonly shouldCenter?: boolean
    readonly style?: StyleProps
}

export default function PageSectionHeading({
    label,
    shouldCenter = false,
    style = {},
}: Props) {
    const styles = useStyles({ shouldCenter })

    return (
        <HeadingMedium style={{ ...styles.root, ...style }}>
            {label}
        </HeadingMedium>
    )
}

function useStyles({ shouldCenter }: { shouldCenter: boolean }) {
    return componentStyles({
        root: {
            textAlign: shouldCenter ? "center" : "left",
        },
    })
}
