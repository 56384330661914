// ! IMPORTANT: This file is used in gatsby-node-helpers and does not support aliases
import Big from "big.js"

import type {
    OutfitterStatus,
    SearchListingsForSportsmenInput,
} from "../graphql/generated/graphql"

import { parseInteger } from "./parsing-helpers"
import { removeUndefinedProps } from "./object-helpers"

export function parseQueryString({
    query,
    onError,
}: {
    query: string
    onError?: (error: unknown) => void
}) {
    try {
        if (query.startsWith("http://") || query.startsWith("https://")) {
            const url = new URL(query)
            return parseQueryParams(url.search)
        }

        // We do this to allow for queries with the following path: /search?query=deer
        const searchParams = query.includes("?") ? query.split("?")[1] : query

        return parseQueryParams(searchParams)
    } catch (error) {
        onError?.(error)
        return {}
    }
}

// Returns the text search as 'text' for resolver inputs
export function getSearchInputFromUrlQueryString({
    parsedParams,
}: {
    parsedParams: Record<string, unknown>
}) {
    return mapSearchParamsToSearchInput(parsedParams)
}

export function mapSearchParamsToSearchInput(
    searchParams: Record<string, unknown>
) {
    const { query, text, ...rest } =
        mapSearchQueryObjectToSearchParams(searchParams)

    return {
        ...rest,
        text: text || query,
    }
}

export function mapSearchQueryObjectToSearchParams(
    searchParams: SearchQueryParams
): SearchQueryParams {
    const {
        query,
        species,
        type,
        startDate,
        endDate,
        priceMin,
        priceMax,
        amenities,
        state,
        country,
        isFeatured,
        hasIncludedLodging,
        text,
        status,
        hasAvailability,
    } = searchParams

    const priceMinNumber = getSingleNumericValue(priceMin)
    const priceMaxNumber = getSingleNumericValue(priceMax)

    const searchQueryParams: SearchQueryParams = {
        query: getSingleStringValue(query) || undefined,
        startDate: getSingleNumericValue(startDate) || undefined,
        endDate: getSingleNumericValue(endDate) || undefined,
        priceMin: priceMinNumber ? dollarsToCents(priceMinNumber) : undefined,
        priceMax: priceMaxNumber ? dollarsToCents(priceMaxNumber) : undefined,
        type: getSingleStringValue(type) || undefined,
        species: getArrayStringValues(species),
        amenities: getArrayStringValues(amenities),
        state: getSingleStringValue(state) || undefined,
        country: getSingleStringValue(country) || undefined,
        isFeatured: getSingleBooleanValue(isFeatured) || undefined,
        hasIncludedLodging:
            getSingleBooleanValue(hasIncludedLodging) || undefined,
        text: getSingleStringValue(text) || undefined,
        status,
        hasAvailability: getSingleBooleanValue(hasAvailability),
    }

    return removeUndefinedProps(searchQueryParams)
}

function getSingleNumericValue(value?: QueryParam) {
    const singleValue = getSingleStringValue(value)
    const numberValue = parseInteger(singleValue)

    return isFinite(numberValue) ? numberValue : undefined
}

export function getSingleBooleanValue(value?: QueryParam) {
    const val = getSingularQueryValue(value)

    if (val === "true") {
        return true
    } else if (val === "false") {
        return false
    }
    return undefined
}

function getArrayStringValues(value?: QueryParam) {
    // We comma delimit rather than redeclare the query param so we still get a 'singular' value
    const stringValue = getSingleStringValue(value)

    if (!stringValue) return undefined

    return stringValue.split(",")
}

function getSingularQueryValue(value?: QueryParam) {
    return Array.isArray(value) ? value[0] : value
}

export function getSingleStringValue(value?: QueryParam) {
    return getSingularQueryValue(value) ?? ""
}

function dollarsToCents(dollars: number): number {
    const cents = multiply(dollars, 100)

    return Math.round(cents)
}
function multiply(num1: number, num2: number): number {
    return Big(num1).mul(num2).toNumber()
}

function parseQueryParams(query?: string) {
    return Object.fromEntries(new URLSearchParams(query))
}

type QueryParam = unknown

export type SearchQueryParams = Omit<
    SearchListingsForSportsmenInput,
    "text"
> & {
    query?: string
    text?: string
    status?: OutfitterStatus
}
